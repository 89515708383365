<template>
  <div>
    <h2 class="text-size-h1 text-bold mb-m">{{ $metaInfo.title }}</h2>
    <div class="mb-l">
      <StageTabs v-model="stage" />
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-12 mb-s">
        <p>
          Выберите один или несколько профилей, чтобы&nbsp;посмотреть расписание
          заданий
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-4 col-xs-12 mb-m">
        <BaseSelect
          v-model="selectedProfiles"
          :options="profilesOptions"
          track-by="id"
          label="title"
          searchable
          multiple
          :allow-empty="false"
          :close-on-select="false"
          placeholder="Выбрать профили">
          <template #selection="{ values, isOpen }">
            <div v-if="values.length === 1 && !isOpen">
              {{ values[0].title }}
            </div>
            <div v-else-if="values.length && !isOpen">
              Выбрано {{ values.length }}
            </div>
          </template>

          <template #noResult>Такого профиля не в списке</template>
        </BaseSelect>
      </div>
      <div
        v-if="activities.length"
        class="col-md-8 col-sm-8 col-xs-12 schedule-filter-wrapper mb-l">
        <div class="schedule-filter">
          <button
            class="schedule-filter__item"
            :class="{ 'is-active': !status }"
            @click="status = null">
            Все
          </button>
          <button
            class="schedule-filter__item"
            :class="{ 'is-active': status === 'upcoming' }"
            @click="status = 'upcoming'">
            Предстоящие
          </button>
          <button
            :class="{ 'is-active': status === 'past' }"
            class="schedule-filter__item"
            @click="status = 'past'">
            Прошедшие
          </button>
        </div>
      </div>
    </div>

    <div>
      <div v-if="activities.length">
        <ScheduleGrid :activities="activities" />
      </div>
      <div v-else>Нет выбранных профилей.</div>
    </div>
  </div>
</template>

<script>
/**
 * @page MentorSchedule
 * Расписание для наставников
 */
import ScheduleGrid from "@/components/schedule/ScheduleGrid.vue";
import StageTabs from "@/components/StageTabs.vue";
import { INDIVIDUAL_STAGE, TEAM_STAGE } from "@/constants";
import { excludeWrongAttempts } from "@/utils/profile";
import dayjs from "@/plugins/dayjs";

const STAGES = {
  1: INDIVIDUAL_STAGE,
  2: TEAM_STAGE,
};
export default {
  name: "MentorSchedule",
  metaInfo() {
    return {
      title: "Расписание",
      titleTemplate: `%s - личный кабинет наставника Национальной технологической олимпиады`,
    };
  },
  components: {
    StageTabs,
    ScheduleGrid,
  },
  data() {
    return {
      stage: 1,
      status: null,
      selectedProfiles: [],
    };
  },
  computed: {
    favoriteProfiles() {
      return Object.keys(this.$store.state.mentor.profiles).map(Number);
    },
    profilesOptions() {
      const { favoriteProfiles } = this;
      const profiles = Object.values(this.profiles);
      return profiles.reduce((acc, value) => {
        const item = {
          id: value.id,
          title: value.title,
        };

        if (favoriteProfiles.includes(value.id)) {
          acc.unshift(item);
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
    },
    profiles() {
      return this.$store.state.profile.profiles;
    },
    activities() {
      const { stage, selectedProfiles } = this;
      const currentStage = STAGES[stage];
      let profiles = Object.values(this.profiles);
      if (selectedProfiles.length) {
        const ids = selectedProfiles.map((n) => n.id);
        profiles = profiles.filter((n) => ids.includes(n.id));
      }

      return Object.values(
        profiles.reduce((acc, profile) => {
          profile.activities.forEach((activity) => {
            if (activity.stage === currentStage && activity.is_active) {
              if (acc[activity.id]) {
                acc[activity.id].profiles.push(profile.id);
              } else {
                let currentAttempts = excludeWrongAttempts(
                  activity.attempts
                ).sort((a, b) => {
                  return (
                    new Date(a.start_at).getTime() -
                    new Date(b.start_at).getTime()
                  );
                });
                // если активность внешняя и без попыток
                // то нужно создать попытку на основе активности
                if (activity.external_url && !activity.attempts?.length) {
                  currentAttempts = [
                    {
                      start_at: activity.start_at,
                      end_at: activity.end_at,
                      title: activity.title,
                      activity_id: activity.id,
                      id: activity.id,
                    },
                  ];
                }
                if (this.status === "upcoming") {
                  currentAttempts = currentAttempts.filter((attempt) => {
                    return +dayjs(attempt.start_at) > Date.now();
                  });
                } else if (this.status === "past") {
                  currentAttempts = currentAttempts.filter((attempt) => {
                    return +dayjs(attempt.end_at) < Date.now();
                  });
                }
                acc[activity.id] = {
                  ...activity,
                  attempts: currentAttempts,
                  profiles: [profile.id],
                };
              }
            }
          });
          return acc;
        }, {})
      );
    },
  },
  created() {
    if (this.favoriteProfiles.length) {
      const profiles = this.profilesOptions.filter((n) =>
        this.favoriteProfiles.includes(n.id)
      );
      this.selectedProfiles = [...profiles];
    } else {
      this.selectedProfiles = [this.profilesOptions[0]];
    }
  },
};
</script>

<style lang="less" scoped>
.schedule-filter {
  display: inline-flex;
  flex-flow: row nowrap;
  background-color: #fff;
  box-shadow: @shadow-default;
  border-radius: @radius-s;
  overflow: hidden;

  &__item {
    box-shadow: none;
    border: 0;
    background-color: transparent;
    font-family: inherit;
    color: @link-color;
    padding: 8px 16px;
    cursor: pointer;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: border 0.3s;
    outline: none;
    text-align: center;

    &:hover,
    &:focus {
      border-bottom-color: fade(@link-color, 20%);
    }

    &.is-active {
      border-bottom-color: @link-color !important;
    }
  }

  &-wrapper {
    text-align: right;

    .media-only-xs({
      text-align: left;
    });
  }
}
</style>
